import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { VideoPlayer } from "./VideoPlayer";
import { Journey, ProjectDetails } from "./data";
import ThumbnailIntro from "./Thumbnail/ThumbnailIntro";
import { APIRequestId, getUserIdFromURL, serverRequest } from "../Services/API";
import { Loader } from "../core/Loader";
import PopUp from "../core/PopUp/PopUp";
import { ModeratedQuiz } from "./ModeratedQuiz";
import { ConfirmPopUp } from "./ConfirmPopUp";
import { JourneyPlayer } from "./JourneyPlayer";
import VideoThumbnail from "./VideoThumbnail/VideoThumbnail";
import { Helmet } from "react-helmet";
import "./ChatBox.scss";

const providerData: {
  projectData: ProjectDetails | null;
  APIRequestId: string;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  results: string[]; // Assuming results are an array of strings, change as needed
  addResult: (result: string) => void;
} = {
  projectData: null,
  APIRequestId,
  userName: "",
  setUserName: () => null,
  results: [],
  addResult: () => null,
};

export const ProjectDataContext = createContext(providerData);

interface ChatBoxProps {
  className?: string;
}
interface Data {
  id: number;
  name: string;
}
const ChatBox: React.FC<ChatBoxProps> = ({ className }) => {
  const [stageData, setStageData] = useState<{
    data: Journey[];
    name: string;
  } | null>();
  //changing it from Journey to any
  const [currentStageData, setCurrentStageData] = useState<any | null>(null);
  const [isJourneyPlaying, setIsJourneyPlaying] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<ProjectDetails | null>(null);
  const [isPopUp, setIsPopUp] = useState<boolean>(false);
  const [replyAnswer, setReplyAnswer] = useState<string>("");
  const [isConfirmPopUp, setIsConfirmPopUp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiRequestId, setApiRequestId] = useState<string>("");
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [mainData, setMainData] = useState(null);
  const [allStageData, setAllStageData] = useState<{
    data: Journey[];
    name: string;
  } | null>();

  // const [results, setResults] = useState<string[]>([]);

  const { results, addResult } = useContext(ProjectDataContext);

  // Function to handle the next question and add a new result
  const handleNextQuestion = (newResult: string) => {
    addResult(newResult);
  };

  const [LocalStagedb, setLocalStagedb] = useState<any>([]);

  //
  //
  const [isprojectSpecificChanges, setisprojectSpecificChanges] =
    useState(false);

  const [tNcText, settNcText] = useState(null);

  const [lastQuizQuestionChecker, setLastQuizQuestionChecker] = useState(0);

  const saveJourneyData = useCallback((data: any) => {
    const requestId = uuidv4();
    setApiRequestId(requestId);
    setIsJourneyPlaying(true);
    // console.log("data", data);
    // console.log(
    //   "projectData?.videoProgressBarStages",
    //   projectData?.videoProgressBarStages
    // );

    setAllStageData(data);
    setStageData(data);

    setCurrentStageData(data.data[0]);
  }, []);

  //const respd = projectData?.videoProgressBarStages;
  //console.log("respd", respd);

  const stageType = useMemo(() => {
    if (projectData?.type === "Standalone") {
      return "standAloneJourney";
    } else if (stageData?.data.length === 1) {
      return "Faq";
    } else {
      return "Journey";
    }
  }, [projectData?.type, stageData?.data.length]);

  const getData = useCallback(
    async (text: string) => {
      // API request
      const userData = getUserIdFromURL();
      setIsLoading(true);
      const data = await serverRequest.post<any>("chatgptApi", {
        question: text,
        userId: userData?.userId,
        projectName: userData?.projectName,
      });
      setIsLoading(false);
      if (data?.data) {
        console.log("DATa data", data);
        saveJourneyData(data);
      }
    },
    [saveJourneyData]
  );

  const onClose = useCallback(() => {
    setIsPopUp(false);
  }, []);

  const handleBackToHome = useCallback((action: "yes" | "no") => {
    if (action === "yes") {
      setIsJourneyPlaying(false);
      setStageData(null);
      setCurrentStageData(null);
    }
    setIsConfirmPopUp(false);
  }, []);

  const resetJourney = useCallback(() => {
    setIsJourneyPlaying(false);
    setCurrentStageData(null);
    setShowPopUp(true);
    if (stageData?.data) setCurrentStageData(stageData.data[0]);
  }, [stageData?.data]);

  const backToHome = useCallback(() => {
    setIsConfirmPopUp(true);
  }, []);

  const saveEngagementData = useCallback(
    (nextStageData: Journey, tagData?: any) => {
      console.log("saveEngagementData nextStageData", nextStageData);
      //console.log("window.href", window.location.href);
      const data: any = {
        senderid: APIRequestId,
        projectName: projectData?.projectName ?? "",
        //projectUrl: projectData?.projectUrl ?? "",
        projectUrl: window.location.href,
        intentName: stageData?.name ?? "",
        userMessage: replyAnswer ?? "",
        type: stageType,
        userId: projectData?.userId ?? "",
        // botMessage: {
        //   stage: nextStageData?.stage ?? "",
        //   video: nextStageData?.video ?? "",
        //   text: nextStageData?.text ?? "",
        //   ReplyType: nextStageData?.ReplyType ?? "",
        // },
        botMessage: nextStageData,
      };
      if (tagData) {
        data["model"] = {
          tag: tagData,
        };
      }
      serverRequest.post<any>("saveData/engagementData/", data);
      // console.log("nextStageData?.ReplyType", nextStageData?.ReplyType);
      // if (
      //   !(
      //     nextStageData?.ReplyType == "videoInput" ||
      //     nextStageData?.ReplyType == "voiceInput"
      //   )
      // ) {
      //   serverRequest.post<any>("saveData/engagementData/", data);
      // }
    },
    [
      projectData?.projectName,
      projectData?.projectUrl,
      projectData?.userId,
      replyAnswer,
      stageData?.name,
      stageType,
    ]
  );

  function getCategory(
    answerArr: string | string[],
    categoryArr: string[][]
  ): string {
    // Ensure answerArr is an array
    if (!Array.isArray(answerArr)) {
      answerArr = [answerArr];
    }

    let categoryCounts: number[] = new Array(categoryArr.length).fill(0); // Initialize counts for each category
    (answerArr as string[]).forEach((answer) => {
      for (let i = 0; i < categoryArr.length; i++) {
        if (categoryArr[i].includes(answer)) {
          categoryCounts[i]++;
          break;
        }
      }
    });
    // Find the index of the category with the maximum count
    const maxCountIndex: number = categoryCounts.indexOf(
      Math.max(...categoryCounts)
    );
    // Return the category corresponding to the maxCountIndex
    return maxCountIndex >= 0 ? `Category ${maxCountIndex + 1}` : "Unknown";
  }

  function countIndices(data: any): { [key: number]: number } {
    const counts: { [key: number]: number } = {};
    data.forEach((item: any) => {
      counts[item.index] = (counts[item.index] || 0) + 1;
    });
    return counts;
  }

  function findMaxIndex(counts: { [key: number]: number }): number {
    let maxIndex = -1;
    let maxCount = -1;
    for (const index in counts) {
      if (counts.hasOwnProperty(index)) {
        if (counts[index] > maxCount) {
          maxCount = counts[index];
          maxIndex = parseInt(index);
        }
      }
    }
    return maxIndex;
  }

  const [forceUpdate, setForceUpdate] = useState(false);

  // Define scoring systems
  const scoringSystems: any = {
    healthanswer1: {
      "Rarely (e.g., less than once a week)": 0,
      "Occasionally (e.g., a few times a week)": 1,
      "Frequently (e.g., daily exposure in your commute or living area)": 2,
      "Constantly (e.g., I live in a highly polluted area)": 3,
    },
    healthanswer2: {
      "Rarely (e.g., less than once a week)": 0,
      "Occasionally (e.g., social smoking)": 1,
      "Frequently (e.g., daily smoking)": 2,
      "Constantly (e.g., chain smoking)": 3,
    },
    healthanswer3: {
      // "No symptoms": 0,
      // "Shortness of breath during mild activities": 1,
      // "Shortness of breath during moderate activities": 2,
      // "Shortness of breath at rest": 3,

      "Persistent cough (lasting more than 3 weeks)": 2,
      "Shortness of breath during mild activities": 2,
      "Frequent wheezing or chest tightness": 2,
      "None of the above": 0,
    },
    healthanswer4: {
      // "Rarely (e.g., during specific tasks)": 0,
      // "Occasionally (e.g., during work or school)": 1,
      // "Frequently (e.g., daily)": 2,
      // "Constantly (e.g., every hour)": 3,
      "No, my workplace is safe from such exposure": 0,
      "Occasionally (e.g., during specific tasks)": 1,
      "Frequently (e.g., daily or weekly exposure)": 2,
      "Constantly (e.g., I work in a hazardous environment)": 3,
    },
    healthanswer5: {
      // "No family history": 0,
      // "Yes, in family members": 1,
      // "Yes, and I have been diagnosed as well": 2,

      "No, no known history": 0,
      "Yes, in family members": 1,
      "Yes, I have been diagnosed": 2,
      Both: 3,
    },
  };

  const [healthresults, setHealthResults] = useState<any>([]);

  const heatharray: any = [];
  const healthData = [...results]; // Initialize with existing results
  const [healthanswer1, setHealthAnswer1] = useState<string>("");
  const [healthanswer2, setHealthAnswer2] = useState<string>("");
  const [healthanswer3, setHealthAnswer3] = useState<string>("");
  const [healthanswer4, setHealthAnswer4] = useState<string>("");
  const [healthanswer5, setHealthAnswer5] = useState<string>("");

  const handleNextStage = useCallback(
    async (stageName?: string, tagData?: any) => {
      if (stageName) {
        const currentData = stageData?.data.find(
          (stage) => stage.stage === stageName
        );
        const currentDataIndex = stageData?.data.findIndex(
          (stage) => stage.stage === stageName
        );
        if (
          stageName === "end" ||
          (stageData && currentDataIndex === stageData?.data.length - 1)
        ) {
          setIsJourneyPlaying(false);
        } else if (
          currentData &&
          stageData &&
          currentDataIndex !== undefined &&
          currentDataIndex < stageData?.data.length
        ) {
          const seenStageDataArr: any = allStageData?.data?.map(
            (o: any, i: number) =>
              currentDataIndex === i ? { ...o, isSeen: true } : o
          );

          //this is to send as a response
          const currentIndexQue = stageData?.data[currentDataIndex];
          console.log("currentIndexQue", currentIndexQue);
          const checkifapi = currentIndexQue.apiUrl;
          //console.log("checkifapi", checkifapi);

          console.log("tagDatatagData", tagData);

          if (tagData) {
            heatharray.push(tagData);
            healthData.push(tagData?.replyAnswer);
            setHealthResults([...results, tagData?.replyAnswer]);
          }
          console.log("heatharray", heatharray);
          console.log("healthData", healthData);
          // setAllStageData({
          //   data: seenStageDataArr,
          //   name: stageData.name,
          // });

          if (tagData && tagData?.stage == "stage3") {
            setHealthAnswer1(tagData?.replyAnswer);
          }
          if (tagData && tagData?.stage == "stage4") {
            setHealthAnswer2(tagData?.replyAnswer);
          }
          if (tagData && tagData?.stage == "stage5") {
            setHealthAnswer3(tagData?.replyAnswer);
          }
          if (tagData && tagData?.stage == "stage6") {
            setHealthAnswer4(tagData?.replyAnswer);
          }
          if (tagData && tagData?.stage == "stage7") {
            setHealthAnswer5(tagData?.replyAnswer);
          }
          console.log("healthanswer1", healthanswer1);
          console.log("healthanswer2", healthanswer2);
          console.log("healthanswer3", healthanswer3);
          console.log("healthanswer4", healthanswer4);
          console.log("healthanswer5", healthanswer5);

          console.log("healthresults", healthresults);

          // Define answers
          const userAnswers = {
            healthanswer1,
            healthanswer2,
            healthanswer3,
            healthanswer4,
            healthanswer5,
          };

          // Calculate total score
          let totalScore = 0;

          for (const [key, answer] of Object.entries(userAnswers)) {
            const score = scoringSystems[key][answer] || 0; // Default to 0 if answer not found
            totalScore += score;
          }

          console.log("Total Score:", totalScore);

          let videoUrl = "";

          const lastQuizQuestionChecker = 6;
          //this is the index of last question, if index is 16 means last question is at stage17
          if (currentDataIndex == lastQuizQuestionChecker) {
            const index = lastQuizQuestionChecker + 2;
            const newArray = [...stageData?.data];

            if (totalScore <= 3) {
              videoUrl =
                "https://bharathealthexperts.com/content/drflow1/lowrisk.mp4";
            }
            if (totalScore >= 4 && totalScore <= 8) {
              videoUrl =
                "https://bharathealthexperts.com/content/drflow1/mediumrisk.mp4";
            }
            if (totalScore >= 9 && totalScore <= 15) {
              videoUrl =
                "https://bharathealthexperts.com/content/drflow1/highrisk.mp4";
            }
            newArray[index] = {
              ...newArray[index],
              video: videoUrl,
            };

            console.log("prevState", seenStageDataArr);
            setStageData((prevState: any) => ({
              ...prevState,
              data: newArray,
            }));
          }

          // Trigger a re-render by updating a dummy state variable
          setForceUpdate((prev: any) => !prev);

          if (checkifapi) {
            try {
              const res: any = await getNextQuestion(currentIndexQue, tagData);
              //console.log("res", res);
              tagData.newresponse = res;
              handleAddData(res);
              handleNextQuestion(res);
              setCurrentStageData(res);
              //console.log("results", results);
              saveEngagementData(
                LocalStagedb[LocalStagedb.length - 1],
                tagData
              );
            } catch (error) {
              console.error("Error fetching next question:", error);
            }
          } else {
            handleAddData(stageData?.data[currentDataIndex + 1]);
            setCurrentStageData(stageData?.data[currentDataIndex + 1]);
            saveEngagementData(stageData?.data[currentDataIndex], tagData);
          }

          console.log("Next Question LocalStagedb", LocalStagedb);
        }
      }
    },
    [stageData, saveEngagementData]
  );

  const handleAddData = (newData: any) => {
    setLocalStagedb((prevData: any) => [...prevData, newData]);
    //localStorage.setItem("ldb", JSON.stringify(LocalStagedb));
  };
  const handleRemoveLatestData = () => {
    setLocalStagedb((prevData: any) => {
      // Create a copy of the current data without the last element
      const newData = prevData.slice(0, -1);
      return newData;
    });
  };

  const getNextQuestion = async (currentIndexQue: any, tagData: any) => {
    const checkifapi = currentIndexQue.apiUrl;
    console.log("checkifapi", checkifapi);
    // const apisliced = checkifapi.slice(54);
    // console.log("apisliced", apisliced);
    console.log("currentIndexQue,getNextQuestion", currentIndexQue);
    const data = await serverRequest.post("apiBackend/" + checkifapi, {
      model: {
        reply: tagData || replyAnswer,
        senderId: APIRequestId,
        currentData: {
          stage: currentIndexQue.stage,
          text: currentIndexQue.text,
          ReplyType: currentIndexQue.ReplyType,
          buttons: currentIndexQue.buttons,
          sampleAnswer: currentIndexQue.sampleAnswer,
          connectorName: null,
        },
      },
    });
    return data;
  };

  const handlePrevStage = useCallback(
    (stageName?: string) => {
      if (stageName) {
        //console.log("stageName", stageName);
        const currentData = stageData?.data.find(
          (stage) => stage.stage === stageName
        );
        //console.log("currentData", currentData);
        const currentDataIndex = stageData?.data.findIndex(
          (stage) => stage.stage === stageName
        );
        //console.log("currentDataIndex", currentDataIndex);
        if (
          currentData &&
          stageData &&
          currentDataIndex !== undefined &&
          currentDataIndex < stageData?.data.length
        ) {
          handleRemoveLatestData();
          setCurrentStageData(LocalStagedb[LocalStagedb.length - 2]);
        }
      }
    },
    [stageData, LocalStagedb]
  );

  const onAudioEnd = useCallback(
    (audioText?: string) => {
      if (audioText) {
        getData(audioText);
      }
    },
    [getData]
  );

  const isPrevVisible = useMemo(() => {
    if (currentStageData) {
      const index = stageData?.data.findIndex(
        (item) => item.stage === currentStageData.stage
      );
      if (index === 0 || stageData?.data.length === 1) {
        return false;
      }
      return true;
    }
    return false;
  }, [currentStageData, stageData]);

  const getInitialData = useCallback(async () => {
    const userData = getUserIdFromURL();
    if (userData) {
      const data = await serverRequest.post<{
        status: "success";
        data?: ProjectDetails;
      }>("getData/projectData", {
        userId: userData?.userId,
        projectName: userData?.projectName,
      });
      const engagementData = {
        senderid: APIRequestId,
        projectName: data.data?.projectName ?? "",
        projectUrl: data.data?.projectUrl ?? "",
        intentName: data.data?.intentName ?? "FAQ_or_Video_Data",
        userMessage: "getStarted",
        type:
          data?.data?.type === "Standalone" ? "standAloneJourney" : "Journey",
        userId: projectData?.userId ?? "",
        botMessage: {
          stage: "Stage0",
          text: data.data?.title ?? "",
          video: "",
          ReplyType: "<start>",
        },
      };
      if (projectData?.userId) {
        serverRequest.post<any>("saveData/engagementData/", engagementData);
      }
      if (data?.data) {
        //console.log("Main Data from Server", data.data);
        const subdata = data.data;
        const videodata = subdata.videoProgressBarStages;
        setMainData(videodata);
        setProjectData(data.data);
        const projectSpecificChangesresp = subdata.projectSpecificChanges;
        const tNcTextresp = subdata.tNcText;
        //console.log(        "Main Data from projectSpecificChangesresp",          projectSpecificChangesresp        );
        setisprojectSpecificChanges(projectSpecificChangesresp);
        settNcText(tNcTextresp);
        const quizcheckerresp = subdata.quizchecker;
        //sconsole.log("Main Data from quizcheckerresp", quizcheckerresp);
        setLastQuizQuestionChecker(quizcheckerresp);
        if (
          data?.data.moderatedQuiz.toString().toLowerCase().includes("true")
        ) {
          setIsPopUp(true);
        } else {
          setIsPopUp(false);
        }
      }
      if (data?.data?.type === "Standalone") {
        saveJourneyData({
          data: data.data?.standAloneJourneyData,
          name: "journeyData",
        });
      }
    }
  }, [projectData?.userId, saveJourneyData]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    setShowPopUp(true);
  }, []);

  const contextData = useMemo(() => {
    return {
      projectData: projectData,
      APIRequestId,
      userName,
      setUserName,
      results,
      addResult,
    };
  }, [projectData, userName, results]);

  return (
    <div className={className}>
      <Helmet>
        {/* Set the title dynamically */}
        <link rel="icon" href={projectData?.logo1} type="image/png"></link>
        <title>{projectData?.brandName ?? "Awareness Hub"}</title>

        {/* Set the meta description dynamically */}
        <meta
          name="description"
          content={projectData?.title ?? "Awareness Hub"}
        />

        <meta
          property="og:title"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta
          property="og:description"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta property="og:image" content={projectData?.logo1} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={projectData?.projectUrl} />
        <meta
          property="og:site_name"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card */}
        <meta
          name="twitter:card"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta
          name="twitter:title"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta
          name="twitter:description"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta name="twitter:image" content={projectData?.logo1} />
        <meta name="twitter:url" content={projectData?.projectUrl} />

        {/* LinkedIn */}
        <meta
          property="og:article:author"
          content={projectData?.brandName ?? "Awareness Hub"}
        />
        <meta
          property="og:article:section"
          content={projectData?.title ?? "Awareness Hub"}
        />
        <meta
          property="og:article:published_time"
          content={new Date().toLocaleDateString()}
        />
        <meta
          property="og:article:modified_time"
          content={new Date().toLocaleDateString()}
        />
      </Helmet>
      <div
        className="chatbox-fixed blur"
        style={{ backgroundImage: `url(${projectData?.thumbnailUrl ?? ""})` }}
      ></div>
      <div className="chatbox-fixed">
        <ProjectDataContext.Provider value={contextData}>
          <div className={`chatbox-container`}>
            {isPopUp && (
              <PopUp>
                <ModeratedQuiz
                  onClose={onClose}
                  projectName={projectData?.projectName}
                />
              </PopUp>
            )}
            {projectData ? (
              <>
                {projectData?.type === "Standalone" ? (
                  showPopUp ? (
                    <VideoThumbnail
                      thumbnail={projectData?.thumbnailUrl ?? ""}
                      title={projectData?.title}
                      brandName={projectData?.brandName}
                      handleSubmit={() => setShowPopUp(false)}
                    />
                  ) : (
                    <>
                      <div className="pill-container">
                        <div style={{ color: "white" }} className="custom-pill">
                          Chatbox JourneyPlayer
                        </div>
                      </div>
                      <JourneyPlayer
                        stageData={mainData}
                        allStageData={allStageData}
                        journeyData={currentStageData}
                        onAudioEnd={onAudioEnd}
                        handleNextStage={handleNextStage}
                        handlePrevStage={handlePrevStage}
                        isPrevVisible={isPrevVisible}
                        thumbnail={projectData?.thumbnailUrl ?? ""}
                        backToHome={resetJourney}
                        replyAnswer={replyAnswer}
                        setReplyAnswer={setReplyAnswer}
                        loading={isLoading}
                        showPopUp={showPopUp}
                        setShowPopUp={setShowPopUp}
                        title={projectData?.title}
                        brandName={projectData?.brandName}
                        isQuizJourney={projectData?.intentName
                          ?.toLowerCase()
                          .includes("quiz")}
                      />
                    </>
                  )
                ) : (
                  <>
                    {isConfirmPopUp && (
                      <PopUp>
                        <ConfirmPopUp onSubmit={handleBackToHome} />
                      </PopUp>
                    )}
                    {isJourneyPlaying ? (
                      <div className="video-container">
                        <div className="pill-container">
                          <div
                            style={{ color: "white" }}
                            className="custom-pill"
                          >
                            Chatbox VideoPlayer
                          </div>
                        </div>
                        <VideoPlayer
                          journeyData={currentStageData}
                          onAudioEnd={onAudioEnd}
                          handleNextStage={handleNextStage}
                          handlePrevStage={handlePrevStage}
                          isPrevVisible={isPrevVisible}
                          thumbnail={projectData?.thumbnailUrl ?? ""}
                          backToHome={backToHome}
                          replyAnswer={replyAnswer}
                          setReplyAnswer={setReplyAnswer}
                          loading={isLoading}
                          showPopUp={showPopUp}
                          setShowPopUp={setShowPopUp}
                        />
                      </div>
                    ) : (
                      <ThumbnailIntro
                        className={isPopUp ? " blur" : ""}
                        onAudioEnd={onAudioEnd}
                        imageURL={projectData?.thumbnailUrl ?? ""}
                        introMessage={projectData?.title}
                        brandName={projectData?.brandName}
                        loading={isLoading}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </ProjectDataContext.Provider>
      </div>
    </div>
  );
};

export default ChatBox;
