import { ChatBox } from "../ChatBox";
import { VideoJS } from "../VideoJs";
// import {VideoJS} from '../VideoJS';

function App() {
  return (
    <div className="App">
      <ChatBox />
    </div>
  );
}

export default App;
