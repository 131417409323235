import { useCallback, KeyboardEvent, useEffect } from "react";
import Send from "../../icons/Send";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";
import "./voiceui.css";
import { useInput } from "./useInput";
// import useSpeechRecognition from "./useSpeechRecognition";
import VoiceRecorder from "./VoiceRecorder";
// import img from "../img/mic-svgrepo-com.svg";
// import micIcon from '../../icons/animated mic.gif'; // import your SVG file
// import micStopIcon from '../../icons/mic-svgrepo-com stop.svg'; // import your SVG file

import { useRef, useState } from "react";
import animatedMic from "../../icons/animated mic.gif"; // import your GIF file
import micIcon from "../../icons/mic3.png"; // import your PNG file
import ReactPlayer from "react-player";
import Play from "../../icons/Play";
import "../VideoThumbnail/videoThumbnail.scss";
import { InfinitySpin, RotatingLines } from "react-loader-spinner";
const VoiceInputReply: React.FC<ReplyTypeProps> = ({
  className,
  onChange,
  value,
  onSubmit,
  label,
  error,
  //setReplyAnswer,
}) => {
  const { isFocused, onBlur, onFocus } = useInput();
  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === "Enter") {
        onSubmit?.();
      }
    },
    [onSubmit]
  );

  const audioChunks = useRef<Blob[]>([]);
  const [recordings, setRecordings] = useState<string | undefined>();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [streamRecording, setStreamRecording] = useState<boolean>(false);
  const [showAudio, setShowAudio] = useState<boolean>(false);
  const [showStartbtn, setshowStartBtn] = useState<boolean>(true);
  const [permission, setPermission] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const [audioDuration, setAudioDuration] = useState<number>(0);
  const [audioCurrentTime, setAudioCurrentTime] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [loading, setloading] = useState(false);
  // const handlePlay = () => {
  //   if (audioRef.current) {
  //     audioRef.current.play();
  //   }
  // };
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // New state for tracking pause

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPaused(true); // Set paused state when audio is paused
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPaused(false); // Reset paused state when audio is playing
      setIsPlaying(true);
    }
  };

  const handlePlaying = () => {
    setIsPlaying(true);
    setIsPaused(false); // Reset paused state
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setIsPaused(false); // Reset states when audio ends
  };
  const startRec = async () => {
    startTimer();
    try {
      audioChunks.current = []; // Clear previous chunks
      //console.log("called startRec");

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (e) => {
        //console.log("called ondataavailable");
        if (e.data.size > 0) {
          audioChunks.current.push(e.data);
        }
      };
      mediaRecorder.onstop = () => {
        console.log("called onstop");
        const audioBlob = new Blob(audioChunks.current, { type: "audio/mpeg" });
        const audioUrl = URL.createObjectURL(audioBlob);
        setRecordings(audioUrl);
        //onChange?.(audioBlob);
      };
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setStreamRecording(true);
    } catch (e) {
      console.error("Err accessing MIC", e);
    }
  };

  const stopRec = async () => {
    stopTimer();
    console.log("called stop");
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setshowStartBtn(false);
      setStreamRecording(false);
      setShowAudio(true);
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    if (audioChunks.current.length > 0) {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      const fileName = "my_voice.wav";
      const voiceName = "userVoice";
      await submitRecording(audioBlob, fileName, voiceName)
        .then(async (res) => {
          console.log("res", res);
          //localStorage.setItem("audiores", res);
          if (res) {
            //setReplyAnswer(res);
          }

          //onChange?.(res);
          // Wait for the onChange callback to complete
          //await new Promise((resolve) => setTimeout(resolve, 0));

          // Now, call onSubmit after the onChange callback has completed
          onSubmit?.();
        })
        .catch((err) => {
          console.log("Err", err);
        });
    } else {
      console.error("No recording available to submit.");
    }
  };

  const submitRecording = async (
    audioBlob: Blob,
    fileName: string,
    voiceName: string
  ) => {
    try {
      const formdata = new FormData();
      // formdata.append("sample_file", audioBlob, fileName);
      // formdata.append("voice_name", voiceName);
      formdata.append("audioFile", audioBlob);
      formdata.append("userId", "videoInput");
      const requestOptions: RequestInit = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(
        //"https://hnylearning.com/awarenessHubBackend_UAT/voiceProcessApi/",

        "https://botapi.mygreenhorn.in/audioSaverAPi/uploadAudioAndGetURL",

        requestOptions
      );
      const result = await response.text();
      onChange?.(result);
      localStorage.setItem("audioResp", result);
      return result;
    } catch (error) {
      console.error(error);
    }
  };
  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const [seconds, setSeconds] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (isActive) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isActive]);

  const startTimer = () => {
    setSeconds(0);
    setIsActive(true);
  };
  const stopTimer = () => {
    setIsActive(false);
  };

  return (
    <>
      {loading ? (
        <>
          {" "}
          <InfinitySpin width="200" color="#4fa94d" />
        </>
      ) : (
        <>
          <div className={`form-group${className ? " " + className : ""}`}>
            <div className="interview-container">
              {/* First Participant */}
              <div className="participant">
                <div className="name">
                  <p style={{ fontSize: 20 }}> {label}</p>
                </div>
              </div>

              {/* Second Participant */}
              {!streamRecording && showStartbtn ? (
                <div className="participant">
                  <div className="">
                    <div className="">
                      <>
                        <img
                          src={micIcon}
                          alt="Microphone"
                          width="110px"
                          height="110px"
                          onClick={startRec}
                        />
                        <p style={{ color: "white" }}>Tap to start</p>
                      </>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* stop */}
              {streamRecording ? (
                <div className="participant">
                  <div className="mic-sonar-wrapper">
                    <div className="sonar-emitter">
                      <div className="mic-container" onClick={stopRec}>
                        <img
                          src={animatedMic}
                          alt="Microphone"
                          width="110px"
                          height="110px"
                        />
                      </div>
                      <div className="sonar-wave"></div>
                    </div>
                    <p style={{ marginTop: 15 }} className="mic-text">
                      Duration: {formatTime(seconds)}
                    </p>
                    <p className="mic-text">Tap mic icon to stop</p>
                  </div>
                </div>
              ) : null}

              {/* Second Participant */}
              {showAudio && !streamRecording ? (
                <div className="participant">
                  <div
                    className="btn btn-rounded"
                    style={{ display: "block", alignItems: "center" }}
                  >
                    <div>
                      <span style={{ marginRight: "10px" }}>Preview</span>
                    </div>
                    <br />

                    {recordings && (
                      <>
                        {/* <audio ref={audioRef} src={recordings} /> */}

                        <audio
                          ref={audioRef}
                          src={recordings}
                          onPlaying={handlePlaying}
                          onEnded={handleEnded}
                        />
                        <button
                          className="btn btn-rounded"
                          onClick={handlePlayPause}
                        >
                          {isPlaying
                            ? "Playing"
                            : isPaused
                            ? "Paused"
                            : "Play Audio"}
                        </button>

                        <div style={{ color: "white" }} className="voice-label">
                          Duration: {formatTime(seconds)}
                        </div>
                      </>
                    )}
                  </div>
                  {!streamRecording && !showStartbtn ? (
                    <>
                      <div
                        style={{
                          display: "block",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <button
                          className="btn btn-rounded"
                          onClick={handleSubmit}
                        >
                          Confirm and Submit
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <button className="btn btn-rounded" onClick={startRec}>
                          Record Again
                        </button>{" "}
                        <br />
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VoiceInputReply;
