import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./replyType.scss";
import { ProjectDataContext } from "../ChatBox";
import { serverRequest } from "../../Services/API";
import { Journey } from "../data";
import { getNum } from "../../shared/utils";

interface JourneyEndReplyProps {
  className?: string;
  journeyData: Journey | null;
  quizData:
    | {
        stage?: string;
        scored?: number;
        actualScore?: number;
        answer: string;
      }[]
    | undefined;
}

const JourneyEndReply: React.FC<JourneyEndReplyProps> = ({
  journeyData,
  quizData,
}) => {
  const [totalScored, setTotalScored] = useState<{
    scored: number;
    actualScored: number;
  }>();
  const { projectData, APIRequestId, userName } =
    useContext(ProjectDataContext);

  console.log("APIRequestId", APIRequestId);
  //console.log("APIRequestId", APIRequestId);

  const [certificateURL, setCertificateURL] = useState<string>("");
  const [isPending, setIsPending] = useState<boolean>(false);

  // const getCertificate = useCallback(async () => {
  //   const data = {
  //     userName: userName ?? "",
  //     quizName: projectData?.projectName ?? "",
  //   };
  //   const response = await serverRequest.post<{
  //     status: string | "success";
  //     certUrl: string;
  //   }>("helper/getQuizCertificate", data);
  //   setIsPending(true);
  //   setCertificateURL(response.certUrl);
  // }, [projectData?.projectName, userName]);

  // const saveEngagementData = useCallback(() => {
  //   const data = {
  //     senderid: APIRequestId,
  //     projectName: projectData?.projectName ?? "",
  //     projectUrl: projectData?.projectUrl ?? "",
  //     intentName: projectData?.intentName ?? "",
  //     userMessage:
  //       `You have scored ${
  //         totalScored
  //           ? totalScored.scored + " out of " + totalScored.actualScored
  //           : 0
  //       }` ?? "",
  //     type: "Standalone",
  //     userId: projectData?.userId ?? "",
  //     botMessage: {
  //       stage: "<>Journey End Reply</>" ?? "",
  //       video: "<>Journey End Reply</>" ?? "",
  //       text: certificateURL ?? "",
  //       ReplyType: "endReply" ?? "",
  //     },
  //   };
  //   if (isPending) {
  //     serverRequest.post<any>("saveData/engagementData/", data);
  //   }
  // }, [
  //   APIRequestId,
  //   certificateURL,
  //   projectData?.intentName,
  //   projectData?.projectName,
  //   projectData?.projectUrl,
  //   projectData?.userId,
  //   totalScored,
  //   isPending,
  // ]);

  const saveEngagementData = useCallback(() => {
    const data = {
      senderid: APIRequestId,
      projectName: projectData?.projectName ?? "",
      projectUrl: projectData?.projectUrl ?? "",
      intentName: projectData?.intentName ?? "",
      userMessage: `You have scored ${
        totalScored
          ? totalScored.scored + " out of " + totalScored.actualScored
          : 0
      }`,
      type: "Standalone",
      userId: projectData?.userId ?? "",
      botMessage: {
        stage: "<>Journey End Reply</>",
        video: "<>Journey End Reply</>",
        text: certificateURL,
        ReplyType: "endReply",
      },
    };
    if (isPending) {
      serverRequest.post<any>("saveData/engagementData/", data);
    }
  }, [
    APIRequestId,
    certificateURL,
    projectData?.intentName,
    projectData?.projectName,
    projectData?.projectUrl,
    projectData?.userId,
    totalScored,
    isPending,
  ]);

  const isPassed = useMemo(() => {
    if (
      projectData?.passingScore !== undefined &&
      totalScored?.scored !== undefined &&
      totalScored?.scored >= projectData?.passingScore
    ) {
      return true;
    }
    return false;
  }, [projectData?.passingScore, totalScored?.scored]);

  // useEffect(() => {
  //   setCertificateURL("");
  //   if (quizData?.length) {
  //     const scored = quizData.reduce(
  //       (total, currentObject) => total + getNum(currentObject.scored),
  //       0
  //     );
  //     const actualScored = quizData.reduce(
  //       (total, currentObject) => total + getNum(currentObject.actualScore),
  //       0
  //     );
  //     setTotalScored({ scored, actualScored });
  //     if (
  //       projectData?.passingScore !== undefined &&
  //       scored >= projectData?.passingScore
  //     ) {
  //       getCertificate();
  //     }
  //   }
  // }, [getCertificate, projectData?.passingScore, quizData]);

  useEffect(() => {
    saveEngagementData();
  }, [saveEngagementData]);

  useEffect(() => {
    sendMailAndSaveAnalysisReportToDB();
  }, []);

  const sendMailAndSaveAnalysisReportToDB = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("authtoken", "Awareness-Hub@7895");
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        senderId: APIRequestId,
      });

      const requestOptions: RequestInit = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://botapi.mygreenhorn.in/saveData/sendNSaveReportTo_DB_HDFC/",
        requestOptions
      );
      const result = await response.text();

      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const [showIframe, setShowIframe] = useState(false);

  const handleClick = () => {
    setShowIframe(true);
  };
  return (
    <div style={{ marginBottom: "-1px" }} className="end-reply">
      {window.location.search === "?pId=LSB:LSBCareerFlow" ||
      window.location.search === "?pId=LSB:LSB_Quiz"
        ? quizData?.length &&
          totalScored && (
            <h3 className="score">
              You have scored {totalScored.scored} out of{" "}
              {totalScored.actualScored}
            </h3>
          )
        : null}
      {isPassed ? (
        <>
          {journeyData?.passText && (
            <label
              className="score"
              dangerouslySetInnerHTML={{ __html: journeyData.passText }}
            ></label>
          )}
        </>
      ) : (
        <>
          {journeyData?.failText && (
            <label
              className="score"
              dangerouslySetInnerHTML={{ __html: journeyData.failText }}
            ></label>
          )}
        </>
      )}
      {certificateURL && (
        <div className="download-link">
          <a href={certificateURL} target="_blank" rel="noreferrer">
            Download certificate
          </a>
        </div>
      )}
      <>
        {journeyData?.text && (
          <label
            className="score"
            dangerouslySetInnerHTML={{ __html: journeyData.text }}
          ></label>
        )}
      </>

      {/* {window.location.search === "?pId=Athena:virtualAssistant" ? (
        <a
          href={`https://reports.lifeskillsbharat.com/?senderId=${APIRequestId}&starttime=111`}
          target="_blank"
          className="btn btn-rounded"
          style={{ textDecoration: "none" }}
        >
          Interactive Talent Profile
        </a>
      ) : (
        <a
          href={`https://reports.lifeskillsbharat.com/?senderId=${APIRequestId}&starttime=111`}
          target="_blank"
          className="btn btn-rounded"
          style={{ textDecoration: "none" }}
        >
          View Report
        </a>
      )} */}
    </div>
  );
};

export default JourneyEndReply;
