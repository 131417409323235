import { useCallback, KeyboardEvent, useEffect, useContext } from "react";
import { ReplyTypeProps } from "./data";
import "./replyType.scss";
import { useInput } from "./useInput";
import { useRef, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import { Button } from "./Button";
import { InfinitySpin, RotatingLines } from "react-loader-spinner";

const VideoInputReply: React.FC<ReplyTypeProps> = ({
  className,
  onChange,
  value,
  onSubmit,
  label,
  error,
  stage,
}) => {
  const { isFocused, onBlur, onFocus } = useInput();
  const onEnter = useCallback(
    (event?: KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === "Enter") {
        onSubmit?.();
      }
    },
    [onSubmit]
  );

  const {
    activeRecordings,
    cancelRecording,
    clearAllRecordings,
    clearError,
    clearPreview,
    closeCamera,
    createRecording,
    devicesById,
    devicesByType,
    download,
    errorMessage,
    muteRecording,
    openCamera,
    pauseRecording,
    resumeRecording,
    startRecording,
    stopRecording,
  } = useRecordWebcam();

  const [videoDeviceId, setVideoDeviceId] = useState<string>("");
  const [audioDeviceId, setAudioDeviceId] = useState<string>("");
  const [hideMainBtn, sethideMainBtn] = useState(true);
  const [showMainRecordSection, setShowMainRecordSection] = useState(true);
  const [showPreview, setshowPreview] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [showhiderecordstop, setShowHideRecordStop] = useState(true);
  const [loading, setloading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [seconds, setSeconds] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [userMediaError, setUserMediaError] = useState<string | null>(null);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (isActive) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isActive]);

  const startTimer = () => {
    setSeconds(0);
    setIsActive(true);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    sethideMainBtn(false);
    setShowMainRecordSection(true);
    console.log("videoDeviceId", videoDeviceId);
    const recording = await createRecording(videoDeviceId, audioDeviceId);
    if (recording) await openCamera(recording.id);
  };

  const MystartRecording = async (RecordingId: any) => {
    setShowHideRecordStop(false);
    await startRecording(RecordingId);
    startTimer();
  };

  const myStopRecording = async (videoDeviceId: any) => {
    setshowPreview(true);
    setShowHideRecordStop(true);
    setShowMainRecordSection(false);
    await stopRecording(videoDeviceId);
    stopTimer();
  };

  const myClearPreview = async (videoDeviceId: any) => {
    sethideMainBtn(true);
    setshowPreview(false);
    await clearPreview(videoDeviceId);
    await clearAllRecordings();
    await start();
  };

  const handleSubmit = async () => {
    console.log();
    setloading(true);
    await submitRecording()
      .then(async (res) => {
        console.log("res", res);
        sethideMainBtn(true);
        setshowPreview(false);
        await clearAllRecordings();
        await start();
        await onSubmit?.();
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  const submitRecording = async () => {
    try {
      const videoBlob: Blob | undefined =
        activeRecordings.length > 0 ? activeRecordings[0]?.blob : undefined;

      if (videoBlob) {
        const formdata = new FormData();
        formdata.append("videoFile", videoBlob, "video.mp4");
        formdata.append("userId", "videoInput");

        const requestOptions: RequestInit = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        const response = await fetch(
          "https://botapi.mygreenhorn.in/videoSaverAPi/uploadVideoAndGetURL",
          requestOptions
        );
        const result = await response.text();

        const resultobj = {
          videoUrl: result,
          Question: label,
          CustomVideoInput: true,
        };

        localStorage.setItem("videoResp", JSON.stringify(resultobj));

        console.log(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Mobile Permission code
  const [mobError, setMobError] = useState<Error | null>(null);
  const [mobilesupported, setMobileSupported] = useState(true);
  const handleUserMedia = async () => {
    try {
      const userMediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(userMediaStream);
      setUserMediaError(null); // Clear any previous errors
    } catch (err) {
      setMobError(err as Error);
      setUserMediaError(
        "Error accessing webcam. Please check your camera permissions and try again."
      );
    }
  };

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setMobileSupported(false);
      setMobError(new Error("getUserMedia is not supported in this browser."));
      return;
    }

    handleUserMedia();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const [showErrorByTimer, setShowErrorByTimer] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorByTimer(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  if (mobError && showErrorByTimer) {
    return (
      <div style={{ color: "white" }}>
        Error accessing webcam: {mobError.message}
      </div>
    );
  }

  if (!stream && showErrorByTimer) {
    return (
      <div style={{ color: "white" }}>
        Error accessing webcam. Please check your camera permissions and try
        again. or You can go back and try again.
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <InfinitySpin width="200" color="#4fa94d" />
      ) : (
        <div
          style={{ bottom: 10 }}
          className={`form-group${className ? " " + className : ""}`}
        >
          <div
            style={{ marginBottom: 0 }}
            className={`form-control text-a-left${error ? " error" : ""}`}
          >
            <div className="input-box ">
              <>
                {hideMainBtn ? (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div className="space-x-2">
                      <button className="btn btn-rounded" onClick={start}>
                        Start Recording
                      </button>
                    </div>
                  </div>
                ) : null}
                <div className="my-2">
                  <p>
                    {mobilesupported ? null : (
                      <div>
                        Sorry, we are currently not available on your device
                        because of some browser-level restrictions. Please
                        re-try this url from a desktop or laptop device
                      </div>
                    )}
                  </p>
                </div>
                {userMediaError && (
                  <div style={{ color: "white" }} className="error-message">
                    <p style={{ color: "white" }}>{userMediaError}</p>
                  </div>
                )}
                <div className="mobilecontainer grid grid-cols-custom gap-4 my-4">
                  {activeRecordings?.map((recording) => (
                    <div
                      className="bg-white rounded-lg px-4 py-4"
                      key={recording.id}
                    >
                      {showMainRecordSection ? (
                        <>
                          <div
                            style={{
                              background: "black",
                              color: "white",
                              fontSize: 13,
                              padding: 7,
                              textAlign: "center",
                            }}
                          >
                            ** Please ensure your video input is 30 seconds or
                            longer. Submissions under 30 seconds
                            will be disqualified
                          </div>

                          <div
                            className="video-container"
                            style={{
                              width: "100%",
                              height: "540px",
                              position: "relative",
                              overflow: "hidden",
                              ...(window.innerHeight <= 700 && {
                                height: "460px",
                              }),
                              ...(window.innerHeight <= 550 && {
                                height: "400px",
                              }),
                              ...(window.innerHeight <= 480 && {
                                height: "300px",
                              }),
                            }}
                          >
                            {showhiderecordstop ? (
                              <></>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  height: "100vh",
                                  paddingLeft: "20px",
                                  margin: "3px",
                                }}
                              >
                                <RotatingLines
                                  visible={true}
                                  strokeColor={"red"}
                                  width="50"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                />
                                <div
                                  className="timer-container"
                                  style={{
                                    position: "absolute",
                                    top: 9,
                                    left: 10,
                                    padding: "0px",
                                    background: "white",
                                    borderRadius: "5px",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    zIndex: 10,
                                  }}
                                >
                                  <div className="voice-label">
                                    {formatTime(seconds)}
                                  </div>
                                </div>
                              </div>
                            )}
                            <video
                              loop
                              autoPlay
                              muted
                              playsInline
                              ref={recording.webcamRef}
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%) scaleX(-1)",
                                zIndex: "-1",
                              }}
                            />
                            <div className="bottom-question-container">
                              <div className="videoquestion">{label}</div>
                            </div>
                          </div>

                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{ marginTop: 5 }}
                              className="space-x-1 space-y-1 my-2"
                            >
                              {showhiderecordstop ? (
                                <Button
                                  inverted
                                  disabled={
                                    recording.status === "RECORDING" ||
                                    recording.status === "PAUSED"
                                  }
                                  onClick={() => MystartRecording(recording.id)}
                                >
                                  Start Recording
                                </Button>
                              ) : (
                                <Button
                                  inverted
                                  onClick={() => myStopRecording(recording.id)}
                                >
                                  Stop Recording
                                </Button>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}

                      {showPreview && recording.previewRef ? (
                        <div
                          className={`${
                            recording.previewRef.current?.src.startsWith(
                              "blob:"
                            )
                              ? "visible"
                              : "hidden"
                          }`}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "515px",
                              position: "relative",
                              overflow: "hidden",

                              ...(window.innerHeight <= 700 && {
                                height: "440px",
                              }),
                              ...(window.innerHeight <= 550 && {
                                height: "400px",
                              }),
                              ...(window.innerHeight <= 480 && {
                                height: "300px",
                              }),
                            }}
                          >
                            <div className="preview-container">
                              <div className="voice-label">Preview</div>
                            </div>
                            <div className="timer-container">
                              <div className="voice-label">
                                {formatTime(seconds)}
                              </div>
                            </div>

                            <video
                              loop
                              autoPlay
                              playsInline
                              ref={recording.previewRef}
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%) scaleX(-1)",
                                zIndex: "-1",
                              }}
                            />
                            {seconds > 25 ? (
                              <div className="bottom-question-container">
                                <div className="previewnotice">
                                  Here’s your PREVIEW VIDEO, Retake the video if
                                  you are not sure or else 'Confirm and submit'
                                </div>
                              </div>
                            ) : (
                              <div className="bottom-question-container">
                                <div className="previewnotice">
                                  ** Sorry, your video is too short. Please
                                  ‘RE-RECORD’ it to be at least 25 seconds long.
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{ marginTop: 5 }}
                              className="space-x-2 my-2"
                            >
                              <Button
                                inverted
                                onClick={() => myClearPreview(recording.id)}
                              >
                                Retake
                              </Button>
                              {seconds > 25 ? (
                                <Button inverted onClick={() => handleSubmit()}>
                                  Confirm and submit
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </>
            </div>
            {error && <p className="error-msg">{error}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoInputReply;
